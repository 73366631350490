import React, { ReactNode } from 'react';
import { Button, ButtonProps } from '@/wag-react/next-components/button';
import { Flex } from '@/wag-react/next-components/Flex';
import { Icon } from '@/wag-react/components/icon';
type Props = {
  dataTestId?: string;
  height?: number;
  icon?: string;
  renderContent?: () => ReactNode;
  width?: number;
  disabledBg?: string;
  iconProps?: {
    isInverted: boolean;
  };
  isValid?: boolean;
} & ButtonProps;
export const CircleButton = ({
  bg = 'primary',
  dataTestId,
  disabledBg = 'backgroundGray',
  height = 40,
  icon = 'default',
  iconProps,
  isValid = true,
  onClick,
  renderContent,
  width = 40,
  ...props
}: Props) => <Button border="none" borderRadius="50%" data-testid={dataTestId} height={height} m="0" p="0" width={width} onClick={onClick} disabled={!isValid} isValid={isValid && Boolean(onClick)} bg={isValid ? bg : disabledBg} {...props} data-sentry-element="Button" data-sentry-component="CircleButton" data-sentry-source-file="CircleButton.tsx">
    <Flex alignItems="center" justifyContent="center" style={{
    pointerEvents: 'none'
  }} ml={icon === 'carat' && !iconProps?.isInverted ? '9px' : 0} data-sentry-element="Flex" data-sentry-source-file="CircleButton.tsx">
      {icon && <Icon name={icon} width="24px" ml={icon === 'carat' && iconProps?.isInverted ? '6px' : 0} height="24px" style={{
      transform: iconProps?.isInverted && 'rotate(180deg)'
    }} {...iconProps} />}

      {renderContent && renderContent()}
    </Flex>
  </Button>;
export default CircleButton;