import React from 'react';
import { Flex } from '../../../../components/Flex';
import { Link } from '@/wag-react/core-components/link';
import { Box } from '../../../../components/Box';
import { Carousel } from '../Carousel/Carousel';
import { Typography } from '../../../../components/Typography';
import { Image } from '../../../../wag-react/next-components/image';
import { petResourcesDataDesktop, petResourcesDataMobile } from './data';
const swiperParams = {
  loop: false,
  loopFillGroupWithBlank: true,
  breakpoints: {
    // https://github.com/facebook/flow/issues/380
    // eslint-disable-next-line no-useless-computed-key
    [768]: {
      slidesPerView: 2,
      spaceBetween: 15,
      slidesPerGroup: 2
    },
    // eslint-disable-next-line no-useless-computed-key
    [1120]: {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesPerGroup: 3
    }
  }
};
export const PetResourceCenter = () => {
  return <Flex width={1} boxSizing="border-box" flexDirection="column" marginBottom="50px" data-sentry-element="Flex" data-sentry-component="PetResourceCenter" data-sentry-source-file="PetResourceCenter.tsx">

      <Box px={[3, 3, 0]} data-sentry-element="Box" data-sentry-source-file="PetResourceCenter.tsx">
        <Typography variant="h2" color="medGray" mb={0} textAlign={['left', 'left', 'center']} width={1} data-sentry-element="Typography" data-sentry-source-file="PetResourceCenter.tsx">
              Pet resource center
        </Typography>
        <Typography variant="h3" color="medGray" textAlign={['left', 'left', 'center']} width={1} data-sentry-element="Typography" data-sentry-source-file="PetResourceCenter.tsx">
              Expert info, helpful tools, and tailored insights for all aspects of pet ownership.
        </Typography>
      </Box>
      <Box display={['none', 'block']} width={1} px={2} data-sentry-element="Box" data-sentry-source-file="PetResourceCenter.tsx">

        <Flex width={1} display={['none', 'flex']} mt={3} alignItems="center" justifyContent="center" data-sentry-element="Flex" data-sentry-source-file="PetResourceCenter.tsx">
          <Carousel swiperParams={{
          ...swiperParams,
          containerProps: {
            marginTop: '50px',
            maxWidth: [0, 600, 1000],
            children: undefined
          }
        }} data-sentry-element="Carousel" data-sentry-source-file="PetResourceCenter.tsx">

            {petResourcesDataDesktop.map(petResourceCol => <Box position="relative" key={JSON.stringify(petResourceCol)}>
                {petResourceCol.map(({
              url,
              title,
              image
            }) => <Link key={url} href={url}>

                    <Flex alignItems="flex-end" borderRadius="16px" height="200px" overflowY="hidden" overflowX="hidden" mb="20px" flexDirection="column">
                      <Image alt={title} src={image} href={url} height="200px" width="324px" title={title} mb={2} />

                      {title && <Flex alignItems="flex-end" backgroundImage="linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.58) 100%);" position="relative" mb="0px" bottom="65px" justifyContent="center" width="324px" zIndex={10}>
                          <Typography hover color="white">
                            {title}
                          </Typography>
                        </Flex>}
                    </Flex>
                  </Link>)}
              </Box>)}
          </Carousel>
        </Flex>
      </Box>
      <Box display={['block', 'none']} overflowX="hidden" data-sentry-element="Box" data-sentry-source-file="PetResourceCenter.tsx">
        <Flex width={1} justifyContent="space-between" alignItems="center" boxSizing="border-box" position="relative" data-sentry-element="Flex" data-sentry-source-file="PetResourceCenter.tsx">
          <Flex overflowX="scroll" overflowY="hidden" px={3} width={1} style={{
          WebkitOverflowScrolling: 'touch'
        }} data-sentry-element="Flex" data-sentry-source-file="PetResourceCenter.tsx">
            {petResourcesDataMobile.map(({
            title,
            url,
            image
          }) => <Box key={title} mx={[1, 2, 3, 4]}>

                <Link key={url} href={url}>
                  <Flex alignItems="flex-end" borderRadius="16px" height="200px" overflowX="hidden" position="relative" width="260px" mb="24px">
                    <Image alt={title} src={image} height="200px" width="324px" title={title}
                // mb={2}
                />

                    {title && <Flex alignItems="flex-end" backgroundImage="linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.58) 100%);" height="80px" position="absolute" mb="0px" justifyContent="center" width={1} zIndex={10}>
                        <Typography hover color="white">
                          {title}
                        </Typography>
                      </Flex>}
                  </Flex>
                </Link>
              </Box>)}
          </Flex>
        </Flex>
      </Box>
    </Flex>;
};