import React from 'react';
import { BoxProps } from '../../wag-react/next-components/box';
import { theme } from '../../wag-react/themes/base-theme';
import { Container } from '../Container';
import { Typography } from '../Typography';
import { StatesColumnList } from './components/StatesColumnList';
export type ColorKeys = keyof typeof theme.colors;
type Props = {
  titleColor?: ColorKeys;
  titleProps?: BoxProps;
  subTitleProps?: BoxProps;
  containerProps?: BoxProps;
};
export const StatesColumnListSection = ({
  titleColor = 'medGray',
  titleProps,
  subTitleProps,
  containerProps
}: Props) => <Container pb={['64px', '104px', '188px']} pt={['80px', '96px', '72px']} {...containerProps} data-sentry-element="Container" data-sentry-component="StatesColumnListSection" data-sentry-source-file="index.tsx">
    <Typography color={theme.colors[titleColor]} m={0} textAlign={['left', 'center']} variant="h2" width={1} {...titleProps} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
      Find a trusted Pet Caregiver near you
    </Typography>
    <Typography color="medGray" fontWeight="400" mb={0} mt={['16px', '24px']} textAlign={['left', 'center']} variant="h3" width={1} {...subTitleProps} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
      Available in 5,300 cities across all 50 states
    </Typography>
    <StatesColumnList mt={['32px', '40px']} data-sentry-element="StatesColumnList" data-sentry-source-file="index.tsx" />
  </Container>;