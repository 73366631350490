import React from 'react';
import { ColumnList } from '../../../../../../wag-react/components/ColumnList';
import { Link } from '../../../../../../wag-react/core-components/link';
import { Box } from '../../../../../../components/Box';
import { Typography } from '../../../../../../components/Typography';
import { URL_CITY_DIRECTORY } from '../../../../../../constants';
import { buildCols } from '../../../../../../utils';
import { cities } from '../../data';
import { useMediaQuery } from '../../../../../../hooks/useMediaQuery';
import { pxToNumber } from '../../../../../../wag-react/common/utils/pxToNumber';
import { theme } from '../../../../../../wag-react/themes/base-theme';
export const CityList = () => {
  const smBreakpoint = pxToNumber(theme.breakpoints[0]);
  const {
    matched: isMobile
  } = useMediaQuery({
    maxWidth: smBreakpoint - 1
  });
  const NUM_COLS_DESKTOP = 6;
  const NUM_COLS_MOBILE = 2;
  const createCityDOMList = () => {
    const cityDOMList = cities.map(city => <Link key={city.slug} href={URL_CITY_DIRECTORY({
      state: city.stateAbbrev.toLowerCase(),
      city: city.slug.toLowerCase()
    })}>
        <Typography color="medGray" fontWeight="bold" mb={0} hover>
          {`${city.city}, ${city.stateAbbrev}`}
        </Typography>

        <Typography color="medGray" variant="legal" m={0} lineHeight={1} hover>
          {`${city.numCaregivers} Pet Caregivers`}
        </Typography>
      </Link>);
    return cityDOMList;
  };
  return <Box width={1} data-sentry-element="Box" data-sentry-component="CityList" data-sentry-source-file="CityList.tsx">
      {isMobile ? <ColumnList cols={buildCols(NUM_COLS_MOBILE, createCityDOMList())} /> : null}
      {!isMobile ? <ColumnList cols={buildCols(NUM_COLS_DESKTOP, createCityDOMList())} /> : null}
    </Box>;
};