import React from 'react';
import { ButtonLink } from '../../../ButtonLink';
import { statesData } from '../../../../data/states';
import { buildCols } from '../../../../utils/buildCols';
import { ColumnList, ColumnListProps } from '../../../../wag-react/components/ColumnList';
const createStateUrl = (state: string) => `/${state.toLowerCase()}`;
const NUM_COLS_DESKTOP = 5;
const NUM_COLS_MOBILE = 2;
type Props = Omit<ColumnListProps, 'cols'>;
export type StatesColumnListProps = Props;
const items = statesData.map(({
  stateAbbrev,
  state
}) => <ButtonLink key={stateAbbrev} color="medGray" href={createStateUrl(stateAbbrev)} fontSize={['mobile-body1', 'tablet-body1', 'desktop-body1']} lineHeight="1.75" justifyContent="flex-start">
    {state}
  </ButtonLink>);
export const StatesColumnList = (props: Props) => {
  const mobileItems = buildCols(NUM_COLS_MOBILE, items);
  const desktopItems = buildCols(NUM_COLS_DESKTOP, items);
  return <>
      <ColumnList display={['flex', null, 'none']} {...props} cols={mobileItems} data-sentry-element="ColumnList" data-sentry-source-file="index.tsx" />
      <ColumnList display={['none', null, 'flex']} {...props} cols={desktopItems} data-sentry-element="ColumnList" data-sentry-source-file="index.tsx" />
    </>;
};