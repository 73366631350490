import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BodyContainer } from '../BodyContainer';
import { Box } from '../../../../components/Box';
import { GlobalStyle as ThemeV2 } from '../../../../wag-react/themes/web-app-theme';
import { Typography } from '../../../../components/Typography';
import { CityList } from './components/CityList';
export const CitiesSection = () => <ThemeProvider theme={ThemeV2} data-sentry-element="ThemeProvider" data-sentry-component="CitiesSection" data-sentry-source-file="CitiesSection.tsx">
    <BodyContainer py={5} px={[3, 3, 0]} data-sentry-element="BodyContainer" data-sentry-source-file="CitiesSection.tsx">
      <Typography variant="h2" color="medGray" m={0} textAlign={['left', 'left', 'center']} width={1} data-sentry-element="Typography" data-sentry-source-file="CitiesSection.tsx">
        Top Wag! cities
      </Typography>

      <Box mt={3} width={1} data-sentry-element="Box" data-sentry-source-file="CitiesSection.tsx">
        <CityList data-sentry-element="CityList" data-sentry-source-file="CitiesSection.tsx" />
      </Box>
    </BodyContainer>
  </ThemeProvider>;