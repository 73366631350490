export const dogFoodBrands = [
  { title: '4Health Dog Food', slug: '4health-dog-food' },
  { title: 'A Pup Above Dog Food', slug: 'a-pup-above-dog-food' },
  { title: 'Acana Dog Food', slug: 'acana-dog-food' },
  { title: 'American Journey Dog Food', slug: 'american-journey-dog-food' },
  { title: 'Annamaet Dog Food', slug: 'annamaet-dog-food' },
  { title: 'Avoderm Dog Food', slug: 'avoderm-dog-food' },
  { title: 'Best Dog Food Without Chicken', slug: 'best-dog-food-without-chicken' },
  { title: 'Bil Jac Dog Food', slug: 'bil-jac-dog-food' },
  { title: 'Blue Buffalo Dog Food', slug: 'blue-buffalo-dog-food' },
  { title: 'ButcherBox Dog Food', slug: 'butcherbox-dog-food' },
  { title: 'Canidae Dog Food', slug: 'canidae-dog-food' },
  { title: 'Castor & Pollux Dog Food', slug: 'castor-pollux-dog-food' },
  { title: 'Cesar Dog Food', slug: 'cesar-dog-food' },
  { title: 'Diamond Dog Food', slug: 'diamond-dog-food' },
  { title: 'Dr. Gary\'s Best Breed Dog Food', slug: 'dr-garys-best-breed-dog-food' },
  { title: 'Dr. Harvey\'s Dog Food Review', slug: 'dr-harveys-dog-food-review' },
  { title: 'Dr. Marty Nature\'s Blend Dog Food', slug: 'dr-marty-natures-blend-dog-food' },
  { title: 'Eagle Pack Dog Food', slug: 'eagle-pack-dog-food' },
  { title: 'Eukanuba Dog Food', slug: 'eukanuba-dog-food' },
  { title: 'Farmina Dog Food', slug: 'farmina-dog-food' },
  { title: 'FirstMate Dog Food', slug: 'firstmate-dog-food' },
  { title: 'Fresh is Best Dog Food', slug: 'fresh-is-best-dog-food' },
  { title: 'Freshpet Select Dog Food', slug: 'freshpet-select-dog-food' },
  { title: 'Front of the Pack Dog Food', slug: 'front-of-the-pack-dog-food' },
  { title: 'Gentle Giants Dog Food', slug: 'gentle-giants-dog-food' },
  { title: 'Grandma Lucy\'s Dog Food', slug: 'grandma-lucys-dog-food' },
  { title: 'Gravy Train Dog Food', slug: 'gravy-train-dog-food' },
  { title: 'Halo Dog Food', slug: 'halo-dog-food' },
  { title: 'Hill\'s Science Diet Dog Food', slug: 'hills-science-diet-dog-food' },
  { title: 'Holistic Select Dog Food', slug: 'holistic-select-dog-food' },
  { title: 'Iams Dog Food', slug: 'iams-dog-food' },
  { title: 'Instinct Dog Food', slug: 'instinct-dog-food' },
  { title: 'Jinx Dog Food', slug: 'jinx-dog-food' },
  { title: 'JustFoodForDogs Dog Food', slug: 'justfoodfordogs-dog-food' },
  { title: 'Kibbles \'n Bits Dog Food', slug: 'kibbles-n-bits-dog-food' },
  { title: 'Kirkland Dog Food', slug: 'kirkland-dog-food' },
  { title: 'Merrick Cat Food', slug: 'merrick-cat-food' },
  { title: 'Merrick Dog Food', slug: 'merrick-dog-food' },
  { title: 'Natural Balance Dog Food', slug: 'natural-balance-dog-food' },
  { title: 'Nature\'s Recipe Dog Food', slug: 'natures-recipe-dog-food' },
  { title: 'Nom Nom Dog Food', slug: 'nom-nom-dog-food' },
  { title: 'Nulo Dog Food', slug: 'nulo-dog-food'},
  { title : 'Nutrisource Dog Food', slug: 'nutrisource-dog-food' },
  {title: 'Nutro Dog Food', slug: 'nutro-dog-food'},
  {title: 'Ollie Dog Food', slug: 'ollie-dog-food'},
  {title: 'Open Farm Dog Food', slug: 'open-farm-dog-food'},
  {title: 'Orijen Dog Food', slug: 'orijen-dog-food'},
  {title: 'Pedigree Dog Food', slug: 'pedigree-dog-food'},
  {title: 'Primal Dog Food', slug: 'primal-dog-food'},
  {title: 'Purina One Dog Food', slug: 'purina-one-dog-food'},
  {title: 'Raised Right Dog Food', slug: 'raised-right-dog-food'},
  {title: 'Redbarn Dog Food', slug: 'redbarn-dog-food'},
  {title: 'Solid Gold Dog Food', slug: 'solid-gold-dog-food'},
  {title: 'Spot Farms Dog Food', slug: 'spot-farms-dog-food'},
  {title: 'Spot Tango Dog Food', slug: 'spot-tango-dog-food'},
  {title: 'Stella and Chewy Dog Food', slug: 'stella-and-chewy-dog-food'},
  {title: 'Sundays Food for Dogs', slug: 'sundays-food-for-dogs'},
  {title: 'Taste of the Wild Dog Food', slug: 'taste-of-the-wild-dog-food'},
  {title: 'The Farmer\'s Dog Fresh Food', slug: 'the-farmers-dog-fresh-food'},
  {title: 'The Honest Kitchen Dog Food', slug: 'the-honest-kitchen-dog-food'},
  {title: 'Victor Dog Food', slug: 'victor-dog-food'},
  {title: 'We Feed Raw Dog Food', slug: 'we-feed-raw-dog-food'},
  {title: 'Wellness Dog Food', slug: 'wellness-dog-food'},
  {title: 'Weruva Dog Food', slug: 'weruva-dog-food'},
  {title: 'Whole Earth Farms Dog Food', slug: 'whole-earth-farms-dog-food'},
  {title: 'Wholehearted Dog Food', slug: 'wholehearted-dog-food'},
  {title: 'Wild Earth Dog Food', slug: 'wild-earth-dog-food'},
  {title: 'Zignature Dog Food', slug: 'zignature-dog-food'},
  {title: 'Acana Cat Food', slug: 'acana-cat-food'},
  {title: 'Blue Buffalo Cat Food', slug: 'blue-buffalo-cat-food'},
  {title: 'Canidae Cat Food', slug: 'canidae-cat-food'},
  {title: 'Halo Cat Food', slug: 'halo-cat-food'},
  {title: 'Nulo Cat Food', slug: 'nulo-cat-food'},
  {title: 'Open Farm Cat Food', slug: 'open-farm-cat-food'},
  {title: 'Orijen Cat Food', slug: 'orijen-cat-food'},
  {title: 'Purina Pro Plan LiveClear Cat Allergen Reducing Cat Food', slug: 'purina-pro-plan-liveclear-cat-allergen-reducing-cat-food'},
  {title: 'Raised Right Cat Food', slug: 'raised-right-cat-food'},
  {title: 'Solid Gold Cat Food', slug: 'solid-gold-cat-food'},
  {title: 'Stella and Chewy\'s Cat Food', slug: 'stella-and-chewys-cat-food'},
  {title: 'Taste of the Wild Cat Food', slug: 'taste-of-the-wild-cat-food'},
  {title: 'The Honest Kitchen Cat Food', slug: 'the-honest-kitchen-cat-food'},
  {title: 'Wellness Cat Food', slug: 'wellness-cat-food'},
  {title: 'Weruva Cat Food', slug: 'weruva-cat-food'},
];