import React, { useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Flex } from '@/wag-react/core-components/flex';
import { CarouselNavigationButton } from '../CarouselNavigationButton';
const SwiperContainer = styled(Flex)`
  & .swiper-container {
    background-color: unset;
  }
`;
type Props = {
  swiperParams: {
    containerProps?: React.ComponentProps<typeof Flex>;
    [key: string]: any;
  };
  children: React.ReactNode;
  navigation?: {
    renderPrevButton?: (swiper: any) => JSX.Element | null;
    renderNextButton?: (swiper: any) => JSX.Element | null;
  };
};
export const Carousel = ({
  children,
  swiperParams,
  navigation = {}
}: Props) => {
  const ref = useRef(null);
  const {
    containerProps,
    ...restSwiperParams
  } = swiperParams;
  return <>
      {navigation.renderPrevButton?.(ref.current?.swiper) || <Flex zIndex={5} width={40} height={40} px={1}>
          <CarouselNavigationButton onClick={() => {
        if (ref.current?.swiper) {
          ref.current.swiper.slidePrev();
        }
      }} iconProps={{
        isInverted: true
      }} />
        </Flex>}
      <SwiperContainer {...containerProps} data-sentry-element="SwiperContainer" data-sentry-source-file="Carousel.tsx">
        <Swiper onSwiper={swiperInstance => {
        ref.current = {
          swiper: swiperInstance
        };
      }} {...restSwiperParams} data-sentry-element="Swiper" data-sentry-source-file="Carousel.tsx">
          {React.Children.map(children, (child, index) => <SwiperSlide key={index}>{child}</SwiperSlide>)}
        </Swiper>
      </SwiperContainer>
      {navigation.renderNextButton?.(ref.current?.swiper) || <Flex zIndex={5} width={40} height={40} px={1}>
          <CarouselNavigationButton onClick={() => {
        if (ref.current?.swiper) {
          ref.current.swiper.slideNext();
        }
      }} />
        </Flex>}
    </>;
};