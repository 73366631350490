import React from 'react';
import { Icon } from '@/wag-react/components/icon';
import { Link } from '@/wag-react/core-components/link';
import { Strong } from '@/wag-react/core-components/strong';
import { Box } from '../Box';
import { Typography } from '../Typography';
import securityPawIcon from './assets/security-paw.svg';
import { theme } from '../../wag-react/themes/base-theme';
type Props = {
  link: string;
};
export const Banner = ({
  link
}: Props) => <Link href={link} target="_blank" data-sentry-element="Link" data-sentry-component="Banner" data-sentry-source-file="index.tsx">
    <Box textAlign={['left', 'center']} mt="0px" backgroundColor={theme.colors.mySin} display="flex" flexDirection="row" alignItems="center" justifyContent="center" minHeight={['88px', '56px']} maxWidth={['768px', '1140px', '100%']} data-sentry-element="Box" data-sentry-source-file="index.tsx">
      <Icon src={securityPawIcon} mr={[2, 2]} ml={[3, 0]} alt="banner security paw icon" width="24px" height="24px" hover data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
      <Typography variant="body1" color="white" style={{
      cursor: 'pointer'
    }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
      Compare top-rated pet insurance plans
        <Strong data-sentry-element="Strong" data-sentry-source-file="index.tsx">
        &nbsp;&#8211; Save up to $273 each year &#8211;&nbsp;
        </Strong>
      Get a custom quote
      </Typography>
    </Box>
  </Link>;