'use client';

import React, { ReactNode, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { ImageProps, Image } from '../../next-components/image';
import { LazyWrapper, LazyWrapperProps } from './LazyWrapper';
import { Placeholder } from './Placeholder';
type Props = {
  src: string;
  srcSet?: string;
  alt?: string;
  aspectRatio?: string;
  imageProps?: ImageProps;
  offset?: number;
  once?: boolean;
  placeholder?: ReactNode;
} & Omit<LazyWrapperProps, 'placeholder'>;
export type LazyImageProps = Props;
export const LazyImage = ({
  alt,
  aspectRatio = '1:1',
  height,
  imageProps,
  offset = 100,
  once = true,
  placeholder = <Placeholder />,
  src,
  srcSet,
  ...others
}: Props) => {
  const [loaded, setLoaded] = useState(false);
  const handleImageLoad = () => setLoaded(true);
  const [aspectRatioX, aspectRatioY] = aspectRatio.split(':');
  const pb = `${+aspectRatioY / +aspectRatioX * 100}%`;
  return <LazyWrapper pb={height || pb} {...others} data-sentry-element="LazyWrapper" data-sentry-component="LazyImage" data-sentry-source-file="LazyImage.tsx">
      <LazyLoad offset={offset} once={once} data-sentry-element="LazyLoad" data-sentry-source-file="LazyImage.tsx">
        {!loaded && placeholder}
        <Image alt={alt} height="100%" left={0} position="absolute" srcSet={srcSet} src={src} style={{
        objectFit: 'cover'
      }} top={0} width="100%" onLoad={handleImageLoad} {...imageProps} data-sentry-element="Image" data-sentry-source-file="LazyImage.tsx" />
      </LazyLoad>
    </LazyWrapper>;
};