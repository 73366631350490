import React from 'react';
import { stringifyUrl } from 'query-string';
import { Button } from '../../../wag-react/next-components/button';
import { Grid } from '../../../wag-react/core-components/grid';
import backgroundMobileServiceWagWalk from '../assets/background-mobile-service-wag-walk.jpg';
import backgroundDesktopServiceWagWalk from '../assets/background-desktop-service-wag-walk.jpg';
import backgroundMobileServiceDropIn from '../assets/background-mobile-service-drop-in.jpg';
import backgroundDesktopServiceDropIn from '../assets/background-desktop-service-drop-in.jpg';
import backgroundMobileServiceTraining from '../assets/background-mobile-service-training.jpg';
import backgroundDesktopServiceTraining from '../assets/background-desktop-service-training.jpg';
import serviceWagWalkIcon from '../assets/icon-wag-walk.svg';
import serviceDropInIcon from '../assets/icon-drop-in.svg';
import serviceTrainingIcon from '../assets/icon-training.svg';
import serviceOvernightIcon from '../assets/icon-overnight.svg';
import serviceWellnessIcon from '../assets/icon-wellness.svg';
import { Routes, QS_SIGNUP_FLOW_SOURCE, QS_SIGNUP_PREFERRED_SERVICE, WalkType, QS_UTM_SOURCE, UTMSource, QS_UTM_MEDIUM, UTMMedium, QS_UTM_CAMPAIGN, UTMCampaign, QS_UTM_CONTENT, UTMContent, QS_UTM_TERM, UTMTerm, WAG_WEBAPP_BOOK_TRAINING_URL, BASE_URL, SignupFlowSource } from '../../../constants';
export type Service = {
  backgroundURLs?: string[];
  description: {
    heading: string;
    body: React.ReactNode;
  };
  firstTimePromotional?: {
    text: string;
    url: string;
    props?: any;
  };
  firstTimePromotionalBlock?: React.ReactNode;
  iconURL: string;
  key: string;
  learnMoreURL?: string;
  learnMore?: {
    url: string;
    text: string;
    props?: any;
  };
  name: string;
  title: string;
};
export const services: Service[] = [{
  backgroundURLs: [backgroundMobileServiceWagWalk, backgroundMobileServiceWagWalk, backgroundDesktopServiceWagWalk],
  description: {
    heading: 'Take our signature service for a spin',
    body: 'Choose between a 20-minute, 30-minute, or 60-minute stroll for your dog. On-demand or pre-scheduled.'
  },
  firstTimePromotional: {
    text: 'Book a walk',
    url: `${BASE_URL}/dog-walking`
  },
  iconURL: serviceWagWalkIcon,
  key: 'service/wag-walk',
  learnMore: {
    url: Routes.DogWalking,
    text: 'Learn more about dog walking'
  },
  name: 'Walk',
  title: 'Paws on the move'
}, {
  backgroundURLs: [],
  description: {
    heading: 'Pack their getaway bag',
    body: <>
          A sleepover for your pet in the comfort of your home or a Pet Caregiver’s home,
          for as many nights as you need.
          Learn more about&nbsp;
          <Button href={Routes.DogSitting} fontFamily="muli" fontWeight="bold" fontSize={['16px', '20px']} lineHeight={['28px', '30px']}>
            pet sitting
          </Button>
          &nbsp;or&nbsp;
          <Button href={Routes.DogBoarding} fontFamily="muli" fontWeight="bold" fontSize={['16px', '20px']} lineHeight={['28px', '30px']}>
            boarding
          </Button>
        </>
  },
  firstTimePromotionalBlock: <Grid gridTemplateColumns={['1fr 1fr', null, '1fr']} gridGap={['8px']}>
        <Button variant="contained" href={`${BASE_URL}/dog-sitting`} fontFamily="muli" fontWeight="bold" fontSize={['16px', '20px']}>
          Book sitting
        </Button>
        <Button variant="contained" href={`${BASE_URL}/dog-boarding`} fontFamily="muli" fontWeight="bold" fontSize={['16px', '20px']}>
          Book boarding
        </Button>
      </Grid>,
  iconURL: serviceOvernightIcon,
  key: 'service/overnight',
  name: 'Overnight',
  title: 'Dog\'s night out'
}, {
  backgroundURLs: [backgroundMobileServiceDropIn, backgroundMobileServiceDropIn, backgroundDesktopServiceDropIn],
  description: {
    heading: 'We’ll pop by to say hi',
    body: 'A 20 minute home visit for pets who don’t need a walk, but love having friends over.'
  },
  iconURL: serviceDropInIcon,
  key: 'service/drop-in',
  name: 'Drop-In',
  title: 'Leave me alone, said no dog ever',
  firstTimePromotional: {
    text: 'Book Drop-in',
    url: `${BASE_URL}/drop-ins`
  },
  learnMore: {
    url: Routes.DropIn,
    text: 'Learn more about drop-in pet care'
  }
}, {
  backgroundURLs: [],
  description: {
    heading: '',
    body: 'Don’t choose between your pet and your wallet. Wag! Wellness helps you provide the best care for your pet at the best price. Compare pet insurance and wellness plans from top-rated providers, or connect with licensed vets for expert advice.'
  },
  firstTimePromotionalBlock: <Grid gridTemplateColumns={['1fr 1fr', null, '1fr']} gridGap={['8px']}>
        <Button variant="contained" href={stringifyUrl({
      url: Routes.PetInsuranceQuoteLink,
      query: {
        [QS_UTM_SOURCE]: UTMSource.Wag,
        [QS_UTM_MEDIUM]: UTMMedium.Web,
        [QS_UTM_CAMPAIGN]: UTMCampaign.HomePage,
        [QS_UTM_CONTENT]: UTMContent.HomePage,
        [QS_UTM_TERM]: UTMTerm.Carousel
      }
    })} fontFamily="muli" fontWeight="bold" fontSize={['16px', '20px']} px={1}>
          Insurance options
        </Button>
        <Button variant="contained" href={stringifyUrl({
      url: BASE_URL + Routes.WagWellnessPlans
    })} fontFamily="muli" fontWeight="bold" fontSize={['16px', '20px']} px={1}>
          Wag! Wellness
        </Button>
      </Grid>,
  iconURL: serviceWellnessIcon,
  key: 'service/wellness',
  name: 'Wag! Wellness',
  title: 'Veterinarians standing by',
  learnMore: {
    url: stringifyUrl({
      url: Routes.PetInsuranceQuoteLink,
      query: {
        [QS_UTM_SOURCE]: UTMSource.Wag,
        [QS_UTM_MEDIUM]: UTMMedium.Web,
        [QS_UTM_CAMPAIGN]: UTMCampaign.HomePage,
        [QS_UTM_CONTENT]: UTMContent.HomePage,
        [QS_UTM_TERM]: UTMTerm.Carousel
      }
    }),
    text: 'Compare Pet Insurance Plans',
    props: {
      target: '_blank'
    }
  }
}, {
  backgroundURLs: [backgroundMobileServiceTraining, backgroundMobileServiceTraining, backgroundDesktopServiceTraining],
  description: {
    heading: 'Sit, stay, learn!',
    body: 'For dogs and cats of all ages and experience levels - work one-on-one with an experienced pet trainer from the comfort of your home.'
  },
  iconURL: serviceTrainingIcon,
  key: 'service/training',
  learnMore: {
    url: Routes.WagServicesDogTraining,
    text: 'Learn more about dog training'
  },
  name: 'Training',
  title: 'Practice makes perfect',
  firstTimePromotional: {
    text: 'Book training',
    url: stringifyUrl({
      url: WAG_WEBAPP_BOOK_TRAINING_URL,
      query: {
        [QS_SIGNUP_PREFERRED_SERVICE]: WalkType.Training,
        [QS_SIGNUP_FLOW_SOURCE]: SignupFlowSource.WebTraining
      }
    })
  }
}];