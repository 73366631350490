import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Typography } from '../../../../wag-react/components/Typography';
import { Box } from '../../../../wag-react/next-components/box';
import { theme } from '../../../../wag-react/themes/base-theme';
import { BodyContainer } from '../BodyContainer';
import { AffiliateGuideList } from './components/AffiliateGuideList';
import { dogFoodCategories, dogFoodBrands } from './components/data';
export const AffiliateGuidesSection = () => <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="AffiliateGuidesSection" data-sentry-source-file="AffiliateGuidesSection.tsx">
    <BodyContainer py={5} maxWidth={['360px', '688px', '1200px']} px={[3, 3, 0]} borderTop="1px solid #979797" data-sentry-element="BodyContainer" data-sentry-source-file="AffiliateGuidesSection.tsx">
      <Typography variant="h2" color="medGray" m={0} textAlign={['left', 'left', 'center']} width={1} data-sentry-element="Typography" data-sentry-source-file="AffiliateGuidesSection.tsx">
        Find the best pet food and pet products for your family
      </Typography>

      <Box mt={3} width={1} display="flex" flexWrap={['wrap', null, 'nowrap']} flexDirection={['column', 'row']} data-sentry-element="Box" data-sentry-source-file="AffiliateGuidesSection.tsx">
        <AffiliateGuideList data={dogFoodCategories} title="Browse by category" page="best-products" containerProps={{
        mb: [3, 0]
      }} data-sentry-element="AffiliateGuideList" data-sentry-source-file="AffiliateGuidesSection.tsx" />
        <AffiliateGuideList data={dogFoodBrands} title="Browse by brand" page="reviews" data-sentry-element="AffiliateGuideList" data-sentry-source-file="AffiliateGuidesSection.tsx" />
      </Box>
    </BodyContainer>
  </ThemeProvider>;