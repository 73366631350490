import React from 'react';
import { Button, Props as ButtonProps } from '../Button';
type Props = ButtonProps;
export type ButtonLinkProps = Props;
export const ButtonLink = ({
  children,
  href,
  ...rest
}: Props) => <Button href={href} {...rest} data-sentry-element="Button" data-sentry-component="ButtonLink" data-sentry-source-file="index.tsx">
    {children}
  </Button>;