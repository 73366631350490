'use client';

import React, { useState, useEffect, useRef } from 'react';
import { Box } from '../../wag-react/next-components/box';
import { Typography } from '../../wag-react/components/Typography';
import { useMediaQuery } from 'react-responsive';
import { WagServices } from '../../containers/HomePageContainer/components/WagServices';
import { services } from '../../containers/HomePageContainer/data/homepage.data';
import { ReasonsToWag } from '../../containers/HomePageContainer/components/ReasonsToWag/ReasonsToWag';
import { ReviewsBlock } from '../../containers/HomePageContainer/components/ReviewsBlock';
import { TrustAndSafetyBlock } from '../../containers/HomePageContainer/components/TrustAndSafetyBlock';
import { FeedingShelterDogsBlock } from '../../containers/HomePageContainer/components/FeedingShelterDogsBlock';
import { AnimalLoversWantedSection } from '../../containers/HomePageContainer/components/AnimalLoversWantedSection';
import { CitiesSection } from '../../containers/HomePageContainer/components/CitiesSection';
import { AffiliateGuidesSection } from '../../containers/HomePageContainer/components/AffiliateGuidesSection';
import { PetResourceCenter } from '../../containers/HomePageContainer/components/PetResourceCenter';
import { scrollToViewById } from '../../utils/scrollToView';
import { useTheme } from '../../hooks/useTheme';
import heroBackgroundDesktop from '../../assets/hero-header-backgrounds/homepage/homepage-hero-desktop.jpg';
import heroBackgroundMobile from '../../assets/hero-header-backgrounds/homepage/homepage-hero-mobile.jpg';
import { Time } from '../../constants/time';
import { HOMEPAGE_HERO_SECTION, QS_UTM_CAMPAIGN, QS_UTM_CONTENT, QS_UTM_MEDIUM, QS_UTM_SOURCE, Routes, UTMCampaign, UTMContent, UTMMedium, UTMSource } from '../../constants';
import { Layout } from '../../components/Layout';
import { StatesColumnListSection } from '../../components/StatesColumnListSection';
import queryString from 'query-string';
export const Home = () => {
  const [selectedService, setSelectedService] = useState(services[0]);
  const [cycledServiceIndex, setCycledServiceIndex] = useState<number>(0);
  const heroCycleIntervalRef = useRef(null);
  const {
    theme
  } = useTheme();
  const isMobile = useMediaQuery({
    maxWidth: theme.breakpoints[0]
  });
  const startHeroCycling = () => {
    const interval = setInterval(() => {
      // Return to zero index if the cycle is at the end
      const serviceIndex = cycledServiceIndex + 1 >= services.length ? 0 : cycledServiceIndex + 1;
      setSelectedService(services[serviceIndex]);
      setCycledServiceIndex(serviceIndex);
    }, Time.TenSeconds);
    heroCycleIntervalRef.current = interval;
  };
  const stopHeroCycling = () => {
    if (!heroCycleIntervalRef.current) {
      return;
    }
    clearInterval(heroCycleIntervalRef.current);
  };
  const handleOnServiceClick = service => {
    setSelectedService(service);
    stopHeroCycling();
    if (isMobile) {
      scrollToViewById(HOMEPAGE_HERO_SECTION);
    }
  };
  useEffect(() => {
    startHeroCycling();
    return () => {
      stopHeroCycling();
    };
  }, [cycledServiceIndex]);
  const bannerUrl = queryString.stringifyUrl({
    url: Routes.PetInsuranceQuoteLink,
    query: {
      [QS_UTM_SOURCE]: UTMSource.Wag,
      [QS_UTM_MEDIUM]: UTMMedium.Web,
      [QS_UTM_CAMPAIGN]: UTMCampaign.HeaderBanner,
      [QS_UTM_CONTENT]: UTMContent.HeaderBanner
    }
  });
  return <Layout banner bannerLink={bannerUrl} data-sentry-element="Layout" data-sentry-component="Home" data-sentry-source-file="Home.tsx">
      <Box data-sentry-element="Box" data-sentry-source-file="Home.tsx">
        <Box id={HOMEPAGE_HERO_SECTION} backgroundImage={[`url(${heroBackgroundMobile})`, null, `url(${heroBackgroundDesktop})`]} backgroundSize="cover" backgroundRepeat="no-repeat" height={['244px', '500px', '400px']} px={[3, 4, '160px']} data-sentry-element="Box" data-sentry-source-file="Home.tsx">
          <Box width={['195px', '525px', '800px']} maxHeight={['64px', '132px']} pt={4} mb={4} data-sentry-element="Box" data-sentry-source-file="Home.tsx">
            <Typography variant="h1" fontSize={['30px', '60px']} fontFamily="muli" color="white" m={0} data-sentry-element="Typography" data-sentry-source-file="Home.tsx">
            Local, trusted pet care
            </Typography>
            <Typography variant="h2" fontSize={['16px', '30px']} fontFamily="muli" color="white" mt="16px" data-sentry-element="Typography" data-sentry-source-file="Home.tsx">
            Book 5-star Pet Caregivers near you
            </Typography>
          </Box>
        </Box>
        <WagServices selected={selectedService} onClick={handleOnServiceClick} data-sentry-element="WagServices" data-sentry-source-file="Home.tsx" />
        <ReasonsToWag data-sentry-element="ReasonsToWag" data-sentry-source-file="Home.tsx" />
        <ReviewsBlock data-sentry-element="ReviewsBlock" data-sentry-source-file="Home.tsx" />
        <TrustAndSafetyBlock data-sentry-element="TrustAndSafetyBlock" data-sentry-source-file="Home.tsx" />
        <FeedingShelterDogsBlock data-sentry-element="FeedingShelterDogsBlock" data-sentry-source-file="Home.tsx" />
        <PetResourceCenter data-sentry-element="PetResourceCenter" data-sentry-source-file="Home.tsx" />
        <AnimalLoversWantedSection data-sentry-element="AnimalLoversWantedSection" data-sentry-source-file="Home.tsx" />
        <CitiesSection data-sentry-element="CitiesSection" data-sentry-source-file="Home.tsx" />
        <StatesColumnListSection data-sentry-element="StatesColumnListSection" data-sentry-source-file="Home.tsx" />
        <AffiliateGuidesSection data-sentry-element="AffiliateGuidesSection" data-sentry-source-file="Home.tsx" />
      </Box>
    </Layout>;
};