import React from 'react';
import LazyLoad from 'react-lazyload';
import { Box } from '@/wag-react/next-components/box';
import { Flex } from '@/wag-react/next-components/Flex';
import { Text } from '@/wag-react/core-components/text';
import Avatar from '@/wag-react/components/Avatar';
import { Rating } from '@/wag-react/components/Rating';
import { getRandomSilhouetteName } from '@/utils/getRandomSilhouetteName';
type Props = {
  rating: number;
  name: string;
  review: string;
  gender?: string;
  src?: string;
};
export const HomePageReview = ({
  gender,
  name,
  rating,
  review,
  src
}: Props) => <>
    <Flex justifyContent={['center']} mb={['-27px']} zIndex="10" data-sentry-element="Flex" data-sentry-source-file="HomePageReview.tsx">
      <LazyLoad once height="56px" offset={100} data-sentry-element="LazyLoad" data-sentry-source-file="HomePageReview.tsx">
        <Avatar icon={gender && getRandomSilhouetteName({
        gender
      })} src={src} bg="#d4d4d4" width={['56px', '64px', '72px']} height={['56px', '64px', '72px']} iconHeight={['56px', '64px', '72px']} iconWidth={['56px', '64px', '72px']} imageProps={{
        alt: `${name}-avatar`
      }} containerProps={{
        overflow: 'visible'
      }} data-sentry-element="Avatar" data-sentry-source-file="HomePageReview.tsx" />
      </LazyLoad>
    </Flex>
    <Flex width={1} maxWidth={['264px', '288px', '328px']} pt={3} pb={2} px={[3, 2]} height={['216px', '224px']} bg="white" borderRadius="12px" boxSizing="border-box" flexDirection="column" alignItems={['center']} data-sentry-element="Flex" data-sentry-source-file="HomePageReview.tsx">
      <Box mt={[2, 3]} data-sentry-element="Box" data-sentry-source-file="HomePageReview.tsx">
        <Rating value={rating} starSpacing={1} data-sentry-element="Rating" data-sentry-source-file="HomePageReview.tsx" />
      </Box>
      <Text color="medGray" fontSize={['14px']} fontFamily="muli" fontWeight="bold" lineHeight={1} mt={2} m={0} data-sentry-element="Text" data-sentry-source-file="HomePageReview.tsx">
        {name}
      </Text>
      <Box width={['216px', '100%']} my={[2, 3]} overflow="hidden" data-sentry-element="Box" data-sentry-source-file="HomePageReview.tsx">
        <Text color="medGray" fontFamily="muli" fontSize={['14px', '14px', '16px']} lineHeight={['20px']} m={0} mr="-50px" pr="50px" style={{
        height: '100%',
        overflowY: 'scroll'
      }} data-sentry-element="Text" data-sentry-source-file="HomePageReview.tsx">
          {review}
        </Text>
      </Box>
    </Flex>
  </>;