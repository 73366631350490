import React from 'react';
import { Flex } from '@/wag-react/core-components/flex';
import { BodyContainer } from '../BodyContainer';
import { ResponsiveIconAndTextBlock } from '../../../../components/ResponsiveIconAndTextBlock';
import data from './trustAndSafetyData';
import { H2 } from '../../../../wag-react/core-components/h2';
export const TrustAndSafetyBlock = () => <BodyContainer data-sentry-element="BodyContainer" data-sentry-component="TrustAndSafetyBlock" data-sentry-source-file="TrustAndSafetyBlock.tsx">
    <Flex bg="white" width={1} color="white" justifyContent="center" px={[0, 4]} pb={2} flexDirection="column" boxSizing="border-box" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="TrustAndSafetyBlock.tsx">
      <H2 type="H2" fontSize={['24px', '30px', '48px']} fontFamily="muli" text="white" maxWidth={[350, '100%']} color="medGray" mb={[3, 4, '70px']} data-sentry-element="H2" data-sentry-source-file="TrustAndSafetyBlock.tsx">
        Trust & Safety
      </H2>
      <Flex width={1} flexDirection={['column', 'column', 'row']} boxSizing="border-box" alignItems="flex-start" mb={[0, 3]} data-sentry-element="Flex" data-sentry-source-file="TrustAndSafetyBlock.tsx">
        {data.map(item => <ResponsiveIconAndTextBlock key={item.icon} headingText={item.headingText} icon={item.icon} text={item.text} {...item} />)}
      </Flex>
    </Flex>
  </BodyContainer>;