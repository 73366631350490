// @flow

import React from 'react';
import { Link } from '../../../../../../wag-react/core-components/link';
import { Typography } from '../../../../../../wag-react/components/Typography';
import { Box } from '../../../../../../wag-react/next-components/box';
import { type ProductGuidesPages, WAGWEB_PRODUCT_GUIDES_URL } from '../../../../../../constants';
type Props = {
  data: {
    slug: string;
    title: string;
  }[];
  page: ProductGuidesPages;
  title: string;
  containerProps?: {
    mb: number[] | number;
  };
};
export const AffiliateGuideList = ({
  data,
  page,
  title,
  containerProps
}: Props) => {
  const createAffiliateGuideDomList = () => {
    const affiliateGuideDomList = data.map(item => <Link key={item.slug} href={WAGWEB_PRODUCT_GUIDES_URL({
      slug: item.slug,
      page
    })}>
        <Typography m={0} color="medGray" lineHeight={2} whiteSpace="nowrap" hover style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>
          {`${item.title} ${page === 'reviews' ? 'Review' : ''} ${new Date().getFullYear()}`}
        </Typography>
      </Link>);
    return affiliateGuideDomList;
  };
  return <Box width={1} {...containerProps} data-sentry-element="Box" data-sentry-component="AffiliateGuideList" data-sentry-source-file="index.tsx">
      <Typography color="medGray" fontWeight="bold" mb={[2, 3]} variant="h3" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
        {title}
      </Typography>
      <Box data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {createAffiliateGuideDomList()}
      </Box>
    </Box>;
};