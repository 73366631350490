import React from 'react';
import { BackgroundProps, FlexboxProps, LayoutProps, SpaceProps, BackgroundColorProps, BorderProps, ColorProps, GridProps, PositionProps, ShadowProps, TypographyProps } from 'styled-system';
import { Box } from '../next-components/box';
import { Flex } from '../next-components/Flex';
type StyledSystemProps = BackgroundProps & BackgroundColorProps & BorderProps & ColorProps & FlexboxProps & GridProps & LayoutProps & PositionProps & ShadowProps & SpaceProps & TypographyProps;
type Props = {
  // ! FIXME: `cols` proper type definition
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cols: any[][];
} & StyledSystemProps;
export type ColumnListProps = Props;
export const ColumnList = ({
  cols,
  ...rest
}: Props) => <Flex textAlign="center" width="auto" {...rest} data-sentry-element="Flex" data-sentry-component="ColumnList" data-sentry-source-file="ColumnList.tsx">
    {cols.map((col, i) => <Flex key={String(col) + i} flexDirection="column" flex={1}>
        {col.map((colItem, ci) => <Box key={String(colItem) + ci} textAlign="left">
            {colItem}
          </Box>)}
      </Flex>)}
  </Flex>;