import React from 'react';
import { Flex } from '../../../../wag-react/next-components/Flex';
import { H2 } from '../../../../wag-react/core-components/h2';
import { BodyContainer } from '../BodyContainer';
import HomePageReviewCarousel from './components/HomePageReviewCarousel/HomePageReviewCarousel';
export const ReviewsBlock = () => <Flex width={1} flexDirection="column" alignItems="strech" data-sentry-element="Flex" data-sentry-component="ReviewsBlock" data-sentry-source-file="ReviewsBlock.tsx">
    <Flex bg="backgroundGray" width={1} color="white" justifyContent="center" px={[0, 0, 2, 4]} pb={4} flexDirection="column" boxSizing="border-box" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="ReviewsBlock.tsx">
      <BodyContainer flexDirection="column" maxWidth={1280} data-sentry-element="BodyContainer" data-sentry-source-file="ReviewsBlock.tsx">
        <H2 fontSize={['24px', '30px', '48px']} fontFamily="muli" lineHeight={2} maxWidth={['250px', '100%']} color="medGray" mb={[4, 5]} textAlign="center" data-sentry-element="H2" data-sentry-source-file="ReviewsBlock.tsx">
          Millions of 5-star services and counting
        </H2>
        <HomePageReviewCarousel data-sentry-element="HomePageReviewCarousel" data-sentry-source-file="ReviewsBlock.tsx" />
      </BodyContainer>
    </Flex>
  </Flex>;