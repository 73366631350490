/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import { Flex } from '../next-components/Flex';
import { Image } from '../core-components/image';
import { Icon } from './icon';
import { BoxProps } from '../next-components/box';
type Props = {
  bg?: BoxProps['bg'];
  containerProps?: BoxProps;
  dataTestId?: string;
  height?: BoxProps['height'];
  icon?: string;
  iconHeight?: string | string[];
  iconProps?: any;
  iconWidth?: string | string[];
  imageProps?: any;
  renderContent?: () => ReactNode;
  src?: string;
  width?: BoxProps['width'];
};
const Avatar = ({
  containerProps,
  dataTestId,
  icon,
  iconProps,
  imageProps,
  renderContent,
  src,
  bg = 'primary',
  height = '90px',
  iconHeight = '90px',
  iconWidth = '90px',
  width = '90px'
}: Props) => <Flex alignItems="center" bg={bg} borderRadius="50%" data-testid={dataTestId} height={height} justifyContent="center" m="0" overflow="hidden" p="0" width={width} {...containerProps} data-sentry-element="Flex" data-sentry-component="Avatar" data-sentry-source-file="Avatar.tsx">
    {icon && !src && <Icon backgroundPosition="center" backgroundSize="cover" height={iconHeight} name={icon} width={iconWidth} {...iconProps} />}

    {src && <Image backgroundPosition="center" backgroundSize="cover" height={height} src={src} width={width} borderRadius="50%" alt="Avatar" {...imageProps} />}

    {renderContent && renderContent()}
  </Flex>;
export default Avatar;