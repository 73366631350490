'use client';

import { useState, useEffect } from 'react';
import { useMediaQuery as rrUseMediaQuery, MediaQueryAllQueryable, MediaQueryMatchers } from 'react-responsive';

/**
 * ! Hack to properly hydrate constants/variables that are based on
 * `useMediaQuery()` return value
 *
 * See https://github.com/contra/react-responsive/issues/162
 */
export const useMediaQuery = (settings: Partial<MediaQueryAllQueryable & {
  query?: string;
}>, device?: MediaQueryMatchers, callback?: (matches: boolean) => void): {
  isClient: boolean;
  matched: boolean | null;
} => {
  const mediaQueryResult = rrUseMediaQuery(settings, device, callback);
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    setIsClient(true);
  }, []);
  return {
    isClient,
    matched: isClient ? mediaQueryResult : null
  };
};