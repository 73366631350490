import React from 'react';
import { Box } from '@/wag-react/next-components/box';
import { Flex } from '@/wag-react/core-components/flex';
import { Image } from '@/wag-react/core-components/image';
import { Text } from '@/wag-react/core-components/text';
import { H2 } from '../../../../wag-react/core-components/h2';
import { BodyContainer } from '../BodyContainer';
import mobileBackgroundImage from './assets/feeding-shelter-dogs-hearts-mobile.svg';
import tabletBackgroundImage from './assets/feeding-shelter-dogs-hearts-tablet.svg';
import desktopBackgroundImage from './assets/feeding-shelter-dogs-hearts-desktop.svg';
import mobileWaveImage from './assets/feeding-shelter-dogs-wave-mobile.png';
import tabletWaveImage from './assets/feeding-shelter-dogs-wave-tablet.png';
import desktopWaveImage from './assets/feeding-shelter-dogs-wave-desktop.png';
import extraLargeWaveImage from './assets/feeding-shelter-dogs-wave-extra-large.png';
import partnershipsImage from './assets/feeding-shelter-dogs-partnerships.svg';
export const FeedingShelterDogsBlock = () => <Flex width={1} flexDirection="column" data-sentry-element="Flex" data-sentry-component="FeedingShelterDogsBlock" data-sentry-source-file="FeedingShelterDogsBlock.tsx">
    <Flex backgroundImage={[`url(${mobileBackgroundImage})`, `url(${tabletBackgroundImage})`, `url(${desktopBackgroundImage})`]} backgroundRepeat="no-repeat" backgroundSize={['cover']} backgroundPosition="top" color="white" width={1} px={3} pt={[0, '250px', 4, '400px']} boxSizing="border-box" justifyContent="center" zIndex={[0, 1]} data-sentry-element="Flex" data-sentry-source-file="FeedingShelterDogsBlock.tsx">
      <BodyContainer alignItems="stretch" data-sentry-element="BodyContainer" data-sentry-source-file="FeedingShelterDogsBlock.tsx">
        <Flex flexDirection={['column', 'column', 'row']} height="100%" alignItems="center" data-sentry-element="Flex" data-sentry-source-file="FeedingShelterDogsBlock.tsx">
          <Flex width={[1, '380px', 0.35]} boxSizing="border-box" data-sentry-element="Flex" data-sentry-source-file="FeedingShelterDogsBlock.tsx">
            <Image src={partnershipsImage} alt="partnership-image" height={['50%', '264px', '100%']} width={[1, '380px', '360px', '500px']} mt={[165, 3, 100, 0]} mb={[0, 3]} data-sentry-element="Image" data-sentry-source-file="FeedingShelterDogsBlock.tsx" />
          </Flex>
          <Flex flexDirection="column" alignItems={['start', 'center', 'start']} width={[1, '518px', 0.65]} data-sentry-element="Flex" data-sentry-source-file="FeedingShelterDogsBlock.tsx">
            <H2 fontFamily="muli" type="H2" fontSize={['24px', '24px', '30px']} mt={[0, 0, '105px']} color="white" mb={[1, 2, 2, 3]} lineHeight={[3, 4, 5, 6]} textAlign={['left', 'center', 'left']} data-sentry-element="H2" data-sentry-source-file="FeedingShelterDogsBlock.tsx">
              Feeding shelter dogs with each walk
            </H2>
            <Text fontFamily="muli" color="white" fontSize={['18px', '18px', '24px']} fontWeight={5} textAlign={['left', 'center', 'left']} my={1} data-sentry-element="Text" data-sentry-source-file="FeedingShelterDogsBlock.tsx">
              {'We\'ve donated over 16,500,000 meals!'}
            </Text>
            <Text color="white" fontSize={['16px', '18px']} fontFamily="muli" textAlign={['left', 'center', 'left']} maxWidth={['auto', '600px']} data-sentry-element="Text" data-sentry-source-file="FeedingShelterDogsBlock.tsx">
              A portion of the proceeds from each walk you book
              helps to feed a shelter dog in your area.
              It’s just one of the many ways Wag! gives back and
              helps you become a part of something larger.
            </Text>
          </Flex>
        </Flex>
      </BodyContainer>
    </Flex>
    <Box backgroundImage={[`url(${mobileWaveImage})`, `url(${tabletWaveImage})`, `url(${desktopWaveImage})`, `url(${extraLargeWaveImage})`]} backgroundSize={['100%']} backgroundRepeat="no-repeat" height={[70, 180, 180, 250]} width={1} zIndex={0} mt={[-1, '-60px']} data-sentry-element="Box" data-sentry-source-file="FeedingShelterDogsBlock.tsx" />
  </Flex>;