import React, { ReactNode } from 'react';

// Note: We've converted this to "cherry-picking" import style
// to see if this will impact the performance in a better way
// This package is the most candidate to "import optimize"
// based on the profiling results
// Next.js have some issues on tree-shaking on TypeScript
// https://github.com/vercel/next.js/issues/12557

import { AppInstallInlineCta } from '../../wag-react/components/app-install-banner/AppInstallInlineCta';
import { Box } from '../Box';
import { SiteFooter } from '../../wag-react/components/site-navigation/SiteFooter/SiteFooter';
import { SITE_HEADER_DESKTOP_HEIGHT, SITE_HEADER_MOBILE_HEIGHT, SITE_HEADER_TABLET_HEIGHT } from '../../wag-react/components/site-navigation/constants';
import { SiteNavigation } from '../../wag-react/components/site-navigation';
import { Banner } from '../Banner';
export type Props = {
  children: ReactNode;
  hideFooter?: boolean;
  banner?: boolean;
  bannerLink?: string;
};
export const Layout = ({
  children,
  hideFooter,
  banner = false,
  bannerLink
}: Props) => <>
    <Box left="0" position="fixed" width={1} zIndex={999} data-sentry-element="Box" data-sentry-source-file="index.tsx">
      {banner && <Banner link={bannerLink} />}
      <SiteNavigation data-sentry-element="SiteNavigation" data-sentry-source-file="index.tsx" />

    </Box>
    <Box as="main" pt={[
  // adjust the padding to account for the banner height
  `${SITE_HEADER_MOBILE_HEIGHT + (banner ? 56 : 0)}px`, `${SITE_HEADER_TABLET_HEIGHT + (banner ? 56 : 0)}px`, `${SITE_HEADER_DESKTOP_HEIGHT + (banner ? 56 : 0)}px`]} data-sentry-element="Box" data-sentry-source-file="index.tsx">
      {children}
    </Box>
    {!hideFooter && <>
        <AppInstallInlineCta display={['flex', null, 'none', null]} mx={2} mb={2} primaryText="Learn more in the Wag! app" />
        <SiteFooter />
      </>}
  </>;