import React from 'react';
import { Flex } from '../../../../../../wag-react/next-components/Flex';
import { Box } from '../../../../../../wag-react/next-components/box';
import { HomePageReview } from './components/HomePageReview';
import { Carousel } from '../../../Carousel';
import homepageWalkReviews from './reviews';
const swiperParams = {
  loop: false,
  loopFillGroupWithBlank: true,
  breakpoints: {
    [768]: {
      slidesPerView: 2,
      spaceBetween: 15,
      slidesPerGroup: 2
    },
    [1120]: {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesPerGroup: 3
    }
  }
};
const HomePageReviewCarousel = () => <Flex width={1} boxSizing="border-box" data-sentry-element="Flex" data-sentry-component="HomePageReviewCarousel" data-sentry-source-file="HomePageReviewCarousel.tsx">
    <Box display={['none', 'block']} width={1} px={2} data-sentry-element="Box" data-sentry-source-file="HomePageReviewCarousel.tsx">
      <Flex width={1} overflow="hidden" display={['none', 'flex']} alignItems="center" justifyContent="space-between" data-sentry-element="Flex" data-sentry-source-file="HomePageReviewCarousel.tsx">
        <Carousel swiperParams={{
        ...swiperParams,
        containerProps: {
          maxWidth: [0, 600, 1000],
          children: undefined
        }
      }} data-sentry-element="Carousel" data-sentry-source-file="HomePageReviewCarousel.tsx">
          {homepageWalkReviews.map((review, index) => <Box key={String(review.name + index)}>
              <HomePageReview {...review} />
            </Box>)}
        </Carousel>
      </Flex>
    </Box>
    <Box display={['block', 'none']} overflowX="hidden" data-sentry-element="Box" data-sentry-source-file="HomePageReviewCarousel.tsx">
      <Flex width={1} justifyContent="space-between" alignItems="center" boxSizing="border-box" position="relative" data-sentry-element="Flex" data-sentry-source-file="HomePageReviewCarousel.tsx">
        <Flex overflowX="scroll" overflowY="hidden" px={3} width={1} style={{
        WebkitOverflowScrolling: 'touch'
      }} data-sentry-element="Flex" data-sentry-source-file="HomePageReviewCarousel.tsx">
          {homepageWalkReviews.map(review => <Box key={review.name} mx={[1, 2, 3, 4]}>
              <HomePageReview {...review} />
            </Box>)}
        </Flex>
      </Flex>
    </Box>
  </Flex>;
export default HomePageReviewCarousel;