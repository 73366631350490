import React, { forwardRef } from 'react';
import { BackgroundProps, BackgroundColorProps, BorderProps, ColorProps, FlexboxProps, GridProps, LayoutProps, PositionProps, ShadowProps, SpaceProps, TypographyProps } from 'styled-system';
import { Box } from './box';
import { BoxProps } from '../../components/Box';
import { CSS } from 'styled-components/dist/types';
type StyledSystemProps = BackgroundProps & BackgroundColorProps & BorderProps & ColorProps & FlexboxProps & GridProps & LayoutProps & PositionProps & ShadowProps & SpaceProps & TypographyProps;
export type FlexProps = StyledSystemProps & {
  children: React.ReactNode;
  boxSizing?: CSS.Property.BoxSizing;
} & BoxProps;
export const Flex = forwardRef(({
  display = 'flex',
  flexShrink = 1,
  children,
  ...rest
}: FlexProps, ref) => <Box display={display} flexShrink={flexShrink} ref={ref} {...rest}>
    {children}
  </Box>);
Flex.displayName = 'Flex';