import React from 'react';
import { Flex } from '../../../../wag-react/core-components/flex';
import { BoxProps } from '../../../../wag-react/next-components/box';
type BodyContainerProps = {
  children?: React.ReactNode;
  alignItems?: string;
  flexDirection?: 'column' | 'row';
} & BoxProps;
export const BodyContainer = ({
  children,
  alignItems = 'center',
  flexDirection = 'column',
  ...rest
}: BodyContainerProps) => <Flex maxWidth={['100%', 688, 1120, 1600]}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
boxSizing='border-box' width={1} height="100%" m="0 auto" alignItems={alignItems} flexDirection={flexDirection} {...rest} data-sentry-element="Flex" data-sentry-component="BodyContainer" data-sentry-source-file="index.tsx">
    {children}
  </Flex>;